import { useEffect, useState } from 'react'
import classNames from 'classnames';
import { useRoute } from 'react-router5';
import { Transition } from 'react-transition-group';
import ReactInputMask from 'react-input-mask';

import Icon from '../../components/Icon';

import { formatPhoneNumber, formatPhoneNumberToServerString, httpClientUpdate, nErrorUpdate, useOutsideClick } from '../../funcs';
import { useAppSelector } from '../../store/hooks';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import './ContactMenu.sass'

interface HttpReport {
  success: boolean
  data: {
    contacts: ContactProps[]
  }
}

interface ContactProps {
  friendly_name: string
  list_contact_id: string
  phone: string
  editing: boolean
}

export default function ContactMenu() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [active, setActive] = useState(false)

  const [report, setReport] = useState<ContactProps[]>([])
  const [contacts, setContacts] = useState<ContactProps[]>([])
  const [newContact, setNewContact] = useState({
    phone: '',
    friendly_name: '',
  })
  const [error, setError] = useState('')
  const [searchField, setSearchField] = useState('')

  const ref = useOutsideClick(handleClickOutside);

  useEffect(() => {
    if (active) {
      loadData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  useEffect(() => {
    if (!!error) {
      setError('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newContact.friendly_name, newContact.phone])

  useEffect(() => {
    let updated = report.filter(item => item.friendly_name.toLowerCase().includes(searchField.toLowerCase()) || item.phone.toLowerCase().includes(searchField.toLowerCase()))

    setContacts(updated)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField])


  // https://2022back4.artemiudintsev.com/api/lists/contacts/report?account_id=88888
  async function loadData() {
    try {
      const { data: { success, data } } = (await httpClientUpdate.get(`/lists/contacts/report`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpReport }
      if (success) {
        setContacts(data.contacts.map(item => {
          return { ...item, editing: false }
        }))
        setReport(data.contacts.map(item => {
          return { ...item, editing: false }
        }))
      } else {
        setContacts([])
      }

    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleAddContact() {
    try {
      // https://2022back4.artemiudintsev.com/api/lists/contacts
      const response = await httpClientUpdate.post(`/lists/contacts`, {
        account_id: activeAccountId,
        friendly_name: newContact.friendly_name,
        phone: formatPhoneNumberToServerString(newContact.phone),
      })

      if (response.data.success) {
        setNewContact({
          phone: '',
          friendly_name: '',
        })

        loadData()
      } else {
        loadData()
      }
    } catch (error) {
      let createdError = nErrorUpdate(error)
      setError(createdError.content.errorText)
      loadData()
    }
  }

  async function removeContact(id: string) {
    try {
      // https://2022back4.artemiudintsev.com/api/lists/contacts/88888050uwc9p6ietm
      const response = await httpClientUpdate.delete(`/lists/contacts/${id}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        loadData()
      } else {
        loadData()
      }
    } catch (error) {
      loadData()
    }
  }

  async function handleSaveChangeContact(index: number) {
    try {
      let item = contacts.filter((item, i) => i === index)

      // https://2022back4.artemiudintsev.com/api/lists/contacts/88888050uwc9p6ietm
      const response = await httpClientUpdate.put(`/lists/contacts/${item[0].list_contact_id}`, {
        account_id: activeAccountId,
        friendly_name: item[0].friendly_name,
        phone: formatPhoneNumberToServerString(item[0].phone),
      })

      if (response.data.success) {
        loadData()
      } else {
        loadData()
      }
    } catch (error) {
      loadData()
    }
  }

  function handleClickOutside() {
    setActive(false)
  }

  function handleChangePhone(index: number, value: string) {
    setContacts(contacts.map((item, i) => {
      if (i === index) {
        return { ...item, phone: value }
      } else {
        return { ...item }
      }
    }))
  }

  function handleChangeName(index: number, value: string) {
    setContacts(contacts.map((item, i) => {
      if (i === index) {
        return { ...item, friendly_name: value }
      } else {
        return { ...item }
      }
    }))
  }

  function handleEditContact(index: number) {
    setContacts(contacts.map((item, i) => {
      if (i === index) {
        return { ...item, editing: true }
      } else {
        return { ...item }
      }
    }))
  }

  return (
    <div
      className='contact_menu_div'
      onClick={() => setActive(true)}
      onFocus={() => setActive(true)}
    >
      <button
        className="_wa _rounded _iconed"
      >
        <Icon style={{ width: '18px', minWidth: '18px', fill: '#6093de' }} icon="task-1" />
      </button>

      <Transition in={active} mountOnEnter={true} unmountOnExit={true} timeout={210}>
        {(state) => (
          <div
            className={classNames("contact_menu", `transition-fade-${state}`)}
            //@ts-ignore
            ref={ref}
          >
            <div
              className='contact_menu-row'
            >
              <input
                type="text"
                placeholder='Friendly Name'
                value={newContact.friendly_name}
                onChange={(event) => setNewContact({ ...newContact, friendly_name: event.target.value })}
              />

              <ReactInputMask
                type="text"
                placeholder='Phone'
                mask="+1 (999) 999-9999"
                value={newContact.phone}
                onChange={({ target: { value } }) => setNewContact({ ...newContact, phone: value })}
              />

              <button
                className="_bordered _blue _wa"
                disabled={newContact.friendly_name === '' || formatPhoneNumberToServerString(newContact.phone).length !== 12}
                onClick={() => handleAddContact()}
              >
                Add
              </button>
            </div>

            {
              error &&
              <div className='contact_menu-row'>
                <span className='error'> {error} </span>
              </div>
            }

            <div className='contact_menu-row'>
              <span className='title'>Search:</span>

              <input
                type="text"
                value={searchField}
                onChange={(event) => setSearchField(event.target.value)}
              />
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: 'calc(90% / 4)' }}>Friendly Name</th>

                  <th style={{ width: 'calc(90% / 4)' }}>Phone</th>

                  <th style={{ width: '5%' }}></th>

                  <th style={{ width: '5%' }}></th>
                </tr>
              </thead>

              {
                contacts.map((item, i) => (
                  <tr key={i}>
                    <td style={{ padding: item.editing ? 0 : '5px 10px', fontSize: '14px' }}>
                      {
                        item.editing ?
                          <input
                            type="text"
                            placeholder='Friendly Name'
                            value={item.friendly_name}
                            onChange={(event) => handleChangeName(i, event.target.value)}
                          /> :
                          item.friendly_name
                      }
                    </td>

                    <td style={{ padding: item.editing ? 0 : '5px 10px', fontSize: '14px' }}>
                      {
                        item.editing ?
                          <ReactInputMask
                            type="text"
                            placeholder='Phone'
                            mask="+1 (999) 999-9999"
                            value={item.phone}
                            onChange={({ target: { value } }) => handleChangePhone(i, value)}
                          /> :
                          formatPhoneNumber(item.phone)
                      }
                    </td>

                    <td>
                      {
                        !item.editing ?
                          <button
                            className="_zeroed _iconed _blue"
                            onClick={() => handleEditContact(i)}
                          >
                            <Icon icon="pencil-14" />
                          </button> :
                          <button
                            className="_zeroed _iconed _blue"
                            disabled={item.friendly_name === '' || formatPhoneNumberToServerString(item.phone).length !== 12}
                            onClick={() => handleSaveChangeContact(i)}
                          >
                            <Icon icon="check-mark-1" />
                          </button>
                      }
                    </td>

                    <td>
                      <button
                        className="_zeroed _iconed _red"
                        onClick={() => removeContact(item.list_contact_id)}
                      >
                        <Icon icon="x-mark-1" />
                      </button>
                    </td>
                  </tr>
                ))
              }
            </table>
          </div>
        )}
      </Transition>
    </div>
  )
}
