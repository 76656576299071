import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import { DateTime } from "luxon"
import moment from "moment"
import classNames from "classnames"
import qs from "qs"
import { useCookies } from "react-cookie";

import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"
import Icon from "../../components/Icon"

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs";
import { getDateRangeByPreset } from "../../funcs/reports"
import { useAppSelector } from '../../store/hooks'

import { DateRangePreset } from "../../models/Misc"
import { WebsiteRequests } from "../../models/WebsiteRequests";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"
import Switcher from "../../components/Switcher";

type FilterWord = 'website' | 'source' | 'type'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DataReport
}

interface DataReport {
  interface: {
    filter_words: {
      website: string[],
      type: string[],
      source: string[],
    },

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  permissions: {
    website_request_action_hidden: boolean
    website_request_report_show: boolean
    website_request_show: boolean
    website_request_show_hidden: boolean
    website_request_website_show: boolean
  }

  website_requests: WebsiteRequests[]
}

function WebsiteRequestPage_List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)
  const user = useAppSelector((store) => store.user)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  let timeZone = user?.time_zone;

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportData, setReportData] = useState<DataReport | null>(null)
  const [localInterface, setLocalInterface] = useState(
    {
      show_hidden: false,
      search: '',
      jobSharePopup: false,
      dateRangeCalendarShown: false,
      dateRangePreset: "today" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.website_request?.filter_field ? {
        source: [] as string[],
        website: [] as string[],
        type: [] as string[],
        ...cookiesSetting.website_request.filter_field,
      } : {
        source: [] as string[],
        website: [] as string[],
        type: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.website_request?.sort_field ? cookiesSetting.website_request.sort_field : 'created_at',
        direction: cookiesSetting && cookiesSetting?.website_request?.sort_type ? cookiesSetting.website_request.sort_type : 'down' as 'down' | 'up'
      },
      sortFields: [{
        span: 'Date',
        value: 'created_at'
      }, {
        span: 'Type',
        value: 'type'
      }, {
        span: 'Source',
        value: 'source'
      }, {
        span: 'First Name',
        value: 'first_name'
      }, {
        span: 'Last Name',
        value: 'last_name'
      }, {
        span: 'Address',
        value: 'address'
      }, {
        span: 'Message',
        value: 'message'
      }]
    })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      show_hidden: false,
      jobSharePopup: false,
      dateRangeCalendarShown: false,
      dateRangePreset: "today" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.website_request?.filter_field ? {
        source: [] as string[],
        website: [] as string[],
        type: [] as string[],
        ...cookiesSetting.website_request.filter_field,
      } : {
        source: [] as string[],
        website: [] as string[],
        type: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.website_request?.sort_field ? cookiesSetting.website_request.sort_field : 'created_at',
        direction: cookiesSetting && cookiesSetting?.website_request?.sort_type ? cookiesSetting.website_request.sort_type : 'down' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)

    setTimeout(() => {
      updated && setReportIsDeprecated(false)
      updated && setSearchButtonActive(false)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Watch date range preset
  useEffect(() => {
    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    !updated && setLocalInterface({
      ...localInterface,
      ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  // Load websiteRequests function
  async function loadWebSiteRequests() {

    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    let activeFilters: any = {}

    Object.keys(localInterface.filter_words).forEach((key: any) => {
      if (localInterface.filter_words[key].length) {
        activeFilters[`filters[${key}]`] = localInterface.filter_words[key]
      }
    })

    if (!localInterface.show_hidden) {
      activeFilters['filters[is_hidden]'] = 0
    }

    let reqData = {
      account_id: activeAccountId,
      limit_rows: cookiesSetting && cookiesSetting?.website_request?.limit_rows ? JSON.stringify(cookiesSetting?.website_request?.limit_rows) : JSON.stringify(reportsMaxRows),
      page: localInterface.page,
      date_start: localInterface.min_date,
      date_end: localInterface.max_date,
      sort_field: localInterface.sort.field,
      sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
      ...activeFilters,
      search: localInterface.search,
    };

    setCookie('settings', { ...cookiesSetting, website_request: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.website_request?.limit_rows ? cookiesSetting?.website_request.limit_rows : reportsMaxRows } })

    try {
      // https://2022back4.artemiudintsev.com/api/website/requests/report?account_id=88888&limit_rows=1&page=1
      const { data: { data: websiteRequestsData, success, error } } = (await httpClientUpdate.get('/website/requests/report', {
        params: reqData
      }
      )) as { data: HttpClientUpdateReport }

      if (success) {
        if (websiteRequestsData.permissions.website_request_report_show) {
          setReportData({
            ...websiteRequestsData,
            interface: {
              ...localInterface,
              ...websiteRequestsData.interface,
              min_date: moment(websiteRequestsData.interface.min_date).startOf('day').toDate(),
              max_date: moment(websiteRequestsData.interface.max_date).endOf('day').toDate(),
            },
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, website_request: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function toggleHidden(event: any, is_hidden: boolean, id: string) {
    try {
      event.preventDefault()
      event.stopPropagation()
      // https://2022back4.artemiudintsev.com/api/website/requests/888885019gqcnt625r
      const response = await httpClientUpdate.put(`/website/requests/${id}`, qs.stringify({
        account_id: activeAccountId,
        is_hidden: !is_hidden ? 1 : 0
      }))
      if (response.data.success) {
        reportData && setReportData({
          ...reportData,
          website_requests: reportData.website_requests.map(item => {
            if (item.website_request_id === id) {
              return {
                ...item,
                is_hidden: !item.is_hidden
              }
            } else {
              return { ...item }
            }
          })
        })
      }
    } catch (error) { }
  }

  // Load jobs on page mount
  useEffect(() => {
    loadWebSiteRequests()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$updater])

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return
    setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date,
    localInterface.show_hidden,
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
      $setUpdater(Math.random())
    }

    if ($router.router.getState().params.dateRangePreset) {
      if ($router.router.getState().params.dateRangePreset === 'weekend') {
        let weekDay = DateTime.now().setZone(timeZone).startOf('day').weekday

        if (weekDay === 1) {
          setLocalInterface({
            ...localInterface,
            dateRangePreset: 'custom',
            min_date: DateTime.now().setZone(timeZone).minus({ days: 3 }).startOf('day').toJSDate(),
            max_date: DateTime.now().setZone(timeZone).minus({ days: 1 }).endOf('day').toJSDate()
          })
        } else if (weekDay === 7) {
          setLocalInterface({
            ...localInterface,
            dateRangePreset: 'custom',
            min_date: DateTime.now().setZone(timeZone).minus({ days: 2 }).startOf('day').toJSDate(),
            max_date: DateTime.now().setZone(timeZone).minus({ days: 1 }).endOf('day').toJSDate()
          })
        } else if (weekDay === 6) {
          setLocalInterface({
            ...localInterface,
            dateRangePreset: 'custom',
            min_date: DateTime.now().setZone(timeZone).minus({ days: 1 }).startOf('day').toJSDate(),
            max_date: DateTime.now().setZone(timeZone).minus({ days: 0 }).endOf('day').toJSDate()
          })
        } else if (weekDay === 5) {
          setLocalInterface({
            ...localInterface,
            dateRangePreset: 'custom',
            min_date: DateTime.now().setZone(timeZone).minus({ days: 0 }).startOf('day').toJSDate(),
            max_date: DateTime.now().setZone(timeZone).minus({ days: 0 }).endOf('day').toJSDate()
          })
        } else {
          setLocalInterface({
            ...localInterface,
            dateRangePreset: 'custom',
            min_date: DateTime.now().setZone(timeZone).minus({ days: weekDay + 2 }).startOf('day').toJSDate(),
            max_date: DateTime.now().setZone(timeZone).minus({ days: weekDay + 1 }).endOf('day').toJSDate()
          })
        }
      } else if ($router.router.getState().params.dateRangePreset === 'today') {
        setLocalInterface({
          ...localInterface,
          dateRangePreset: $router.router.getState().params.dateRangePreset,
          min_date: DateTime.now().setZone(timeZone).startOf('day').toJSDate(),
          max_date: DateTime.now().setZone(timeZone).endOf('day').toJSDate(),
        })
      } else {
        setLocalInterface({
          ...localInterface,
          dateRangePreset: $router.router.getState().params.dateRangePreset,
          min_date: DateTime.now().setZone(timeZone).minus({ days: 1 }).startOf('day').toJSDate(),
          max_date: DateTime.now().setZone(timeZone).minus({ days: 1 }).endOf('day').toJSDate(),
        })
      }

      $setUpdater(Math.random())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  // Is all filters selected function
  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  // Filter switch function
  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option: string) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  // Render function
  return (<>
    {reportData ? (
      <div className="JobsPage_List" >

        { /* Page header */}
        <div className="page-header">
          <h1>Website Requests</h1>
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          dateRangePreset={localInterface.dateRangePreset}
          onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value })}

          minDate={localInterface.min_date}
          onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom' })}

          maxDate={localInterface.max_date}
          onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom' })}

          updateButtonActive={reportIsDeprecated}
          onUpdate={() => $setUpdater(Math.random())}

          phoneCall={phoneCall}
          navActive={navActive.is}

          childrenAfterSearch={(
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <span style={{ whiteSpace: 'nowrap' }}>Show hidden:</span>

              <Switcher
                checked={localInterface.show_hidden}
                onChange={(value) => setLocalInterface({ ...localInterface, show_hidden: value })}
              />
            </div>
          )}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          cookie_reportsMaxRows={cookiesSetting?.website_request?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, website_request: { ...cookiesSetting.website_request, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => {
            setLocalInterface({ ...localInterface, page: 1 })
            $setUpdater(Math.random())
          }}
          onPrevPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
            $setUpdater(Math.random())
          }}
          onNextPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
            $setUpdater(Math.random())
          }}
          onPagesEnd={() => {
            setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
            $setUpdater(Math.random())
          }}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />

        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <ReportTableField
                  contents={(<span>Date</span>)}

                  sortDirection={localInterface.sort.field === 'created_at' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'created_at', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                {
                  reportData.permissions.website_request_website_show &&
                  <ReportTableField
                    contents={(<span>Website</span>)}

                    allFiltersSelected={isAllFiltersSelected("website")}
                    onAllFiltersChange={(value) => switchFilter("website", "All", value)}

                    filterWords={reportData.interface.filter_words.website.map((filterWord) => ({
                      word: filterWord,
                      selected: isFilterSelected("website", filterWord)
                    }))}
                    onFilterChange={(value) => switchFilter("website", value.word, value.selected)}
                    onFilterFire={() => $setUpdater(Math.random())}

                    sortDirection={localInterface.sort.field === 'website' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'website', direction: value } })}
                  />
                }
                <ReportTableField
                  contents={(<span>Type</span>)}

                  allFiltersSelected={isAllFiltersSelected("type")}
                  onAllFiltersChange={(value) => switchFilter("type", "All", value)}

                  filterWords={reportData.interface.filter_words.type.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("type", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("type", value.word, value.selected)}
                  onFilterFire={() => $setUpdater(Math.random())}

                  sortDirection={localInterface.sort.field === 'type' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'type', direction: value } })}
                />
                <ReportTableField
                  contents={(<span>Source</span>)}

                  allFiltersSelected={isAllFiltersSelected("source")}
                  onAllFiltersChange={(value) => switchFilter("source", "All", value)}
                  onFilterFire={() => $setUpdater(Math.random())}

                  filterWords={reportData.interface.filter_words.source.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("source", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("source", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'source' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'source', direction: value } })}
                />
                <ReportTableField
                  contents={(<span>First Name</span>)}

                  sortDirection={localInterface.sort.field === 'first_name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'first_name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Last Name</span>)}

                  sortDirection={localInterface.sort.field === 'last_name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'last_name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                {/* <ReportTableField
                  contents={(<span>Phone</span>)}

                  sortDirection={localInterface.sort.field === 'phone' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'phone', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Email</span>)}

                  sortDirection={localInterface.sort.field === 'email' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'email', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                /> */}
                <ReportTableField
                  contents={(<span>Address</span>)}

                  sortDirection={localInterface.sort.field === 'address' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'address', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Message</span>)}

                  sortDirection={localInterface.sort.field === 'message' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'message', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
              </tr>
            </thead>
            {reportData.website_requests.map((item, i) => (
              <tr
                key={i}
                style={{
                  position: 'relative',
                  color: !!item.is_hidden ? 'rgba(142, 142, 147, .3)' : 'inherit',
                  cursor: reportData.permissions.website_request_show ? 'pointer' : 'inherit',
                  display: !reportData.permissions.website_request_show_hidden && !!item.is_hidden ? 'none' : 'table-row'
                }}
                onClick={() =>
                  reportData.permissions.website_request_show &&
                  item.website_request_id &&
                  $router.router.navigate('website_requests.item', {
                    companyId: activeAccountId,
                    website_requestId: item.website_request_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.website_request_show &&
                  item.website_request_id &&
                  handleChangeMenu(event, item.website_request_id, 'website_requests', i)}
              >
                <td>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                      {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at) !== 'Invalid DateTime' ? dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at) : item.created_at}
                    </div>

                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: '10px'
                    }} >
                      <div
                        style={{
                          width: '16px',
                          height: '16px',
                          top: '2px',
                          left: '2px',
                          marginBottom: '10px'
                        }}
                      >
                        <Icon
                          icon="check-mark-1"
                          style={{
                            fill: !!item.is_closed ? '#00FF00' :
                              !!item.is_in_progress ? 'rgb(255, 183, 0)' :
                                'rgba(142, 142, 147, .3)'
                          }}
                        />
                      </div>

                      {
                        reportData.permissions.website_request_action_hidden &&
                        <div
                          style={{
                            width: '16px',
                            height: '16px',
                            top: '2px',
                            right: '2px',
                            cursor: reportData.permissions.website_request_action_hidden ? 'pointer' : 'inherit'
                          }}
                          onClick={(event) => reportData.permissions.website_request_action_hidden && toggleHidden(event, item.is_hidden, item.website_request_id)}
                        >
                          {!!item.is_hidden ?
                            <Icon icon="eye-4" /> :
                            <Icon
                              style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                              viewBox="0 0 1024 1024"
                              icon="eye-block"
                            />
                          }
                        </div>
                      }
                    </div>
                  </div>
                </td>
                {
                  reportData.permissions.website_request_website_show &&
                  <td>{item.website}</td>
                }
                <td>{item.type}</td>
                <td>{item.source}</td>
                <td>{item.first_name}</td>
                <td>{item.last_name}</td>
                {/* <td>{formatPhoneNumber(item.phone)}</td>
                <td>{item.email}</td> */}
                <td>{item.address}</td>
                <td>
                  {item.message}
                  {
                    reportData.permissions.website_request_show &&
                    showContext === `website_requests_${item.website_request_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('website_requests.item', {
                            companyId: activeAccountId,
                            website_requestId: item.website_request_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/website_requests/${item.website_request_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Medum screen table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <tr></tr>
            {reportData.website_requests.map((item, i) => (
              <tr
                key={i}
                style={{
                  position: 'relative',
                  color: !!item.is_hidden ? 'rgba(142, 142, 147, .3)' : 'inherit',
                  cursor: reportData.permissions.website_request_show ? 'pointer' : 'inherit',
                  display: !reportData.permissions.website_request_show_hidden && !!item.is_hidden ? 'none' : 'table-row'
                }}
                onClick={() =>
                  reportData.permissions.website_request_show &&
                  item.website_request_id &&
                  $router.router.navigate('website_requests.item', {
                    companyId: activeAccountId,
                    website_requestId: item.website_request_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.website_request_show &&
                  item.website_request_id &&
                  handleChangeMenu(event, item.website_request_id, 'website_requests', i)}
              >
                <td>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }} >
                      <div>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at) !== 'Invalid DateTime' ? dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at) : item.created_at}</div>
                      {
                        reportData.permissions.website_request_website_show &&
                        <div>{item.website}</div>
                      }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: '10px'
                      }} >
                        <div
                          style={{
                            width: '16px',
                            height: '16px',
                            top: '2px',
                            left: '2px',
                            marginBottom: '10px'
                          }}
                        >
                          <Icon
                            icon="check-mark-1"
                            style={{
                              fill: !!item.is_closed ? '#00FF00' :
                                !!item.is_in_progress ? 'rgb(255, 183, 0)' :
                                  'rgba(142, 142, 147, .3)'
                            }}
                          />
                        </div>

                        {
                          reportData.permissions.website_request_action_hidden &&
                          <div
                            style={{
                              width: '16px',
                              height: '16px',
                              top: '2px',
                              right: '2px',
                              cursor: reportData.permissions.website_request_action_hidden ? 'pointer' : 'inherit'
                            }}
                            onClick={(event) => reportData.permissions.website_request_action_hidden && toggleHidden(event, item.is_hidden, item.website_request_id)}
                          >
                            {
                              !!item.is_hidden ?
                                <Icon icon="eye-4" /> :
                                <Icon
                                  style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                                  viewBox="0 0 1024 1024"
                                  icon="eye-block"
                                />
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div>{item.source} </div>
                  <div>{item.type}</div>
                </td>
                <td>
                  <div>{item.first_name}</div>
                  <div>{item.last_name}</div>
                </td>
                {/* <td>
                  <div>{formatPhoneNumber(item.phone)}</div>
                  <div>{item.email}</div>
                </td> */}
                <td>
                  <div>Address: {item.address}</div>
                  <div>&nbsp;</div>
                </td>
                <td>
                  <div>Message: {item.message}</div>
                  {
                    reportData.permissions.website_request_show &&
                    showContext === `website_requests_${item.website_request_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('website_requests.item', {
                            companyId: activeAccountId,
                            website_requestId: item.website_request_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/website_requests/${item.website_request_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>

            {reportData.website_requests.map((item, i: number) => (
              <div
                key={i}
                className="item"
                style={{
                  color: !!item.is_hidden ? 'rgba(142, 142, 147, .3)' : 'inherit',
                  display: !reportData.permissions.website_request_show_hidden && !!item.is_hidden ? 'none' : 'flex'
                }}
                onClick={() =>
                  reportData.permissions.website_request_show &&
                  item.website_request_id &&
                  $router.router.navigate('website_requests.item', {
                    companyId: activeAccountId,
                    website_requestId: item.website_request_id,
                    localInterface: localInterface,
                  }, { reload: true })}
              >
                <div className="__top">
                  <div className="__left">
                    <div style={{
                      display: 'flex',
                    }} >
                      <div
                        style={{
                          width: '16px',
                          height: '16px',
                          top: '2px',
                          left: '2px',
                          marginRight: '10px'
                        }}
                      >
                        <Icon
                          icon="check-mark-1"
                          style={{
                            fill: !!item.is_closed ? '#00FF00' :
                              !!item.is_in_progress ? 'rgb(255, 183, 0)' :
                                'rgba(142, 142, 147, .3)'
                          }}
                        />
                      </div>

                      {
                        reportData.permissions.website_request_action_hidden &&
                        <div
                          style={{
                            width: '16px',
                            height: '16px',
                            top: '2px',
                            right: '2px',
                            cursor: reportData.permissions.website_request_action_hidden ? 'pointer' : 'inherit'
                          }}
                          onClick={(event) => reportData.permissions.website_request_action_hidden && toggleHidden(event, item.is_hidden, item.website_request_id)}
                        >
                          {
                            !!item.is_hidden ?
                              <Icon icon="eye-4" /> :
                              <Icon
                                style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                                viewBox="0 0 1024 1024"
                                icon="eye-block"
                              />
                          }
                        </div>
                      }
                    </div>
                    {
                      reportData.permissions.website_request_website_show &&
                      <div>
                        <strong>{item.website}</strong>
                      </div>
                    }
                    <div>
                      {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at) !== 'Invalid DateTime' ? dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at) : item.created_at}
                    </div>
                  </div>

                  <div className="__right">
                    <div>
                      Type: <b>{item.type}</b>
                    </div>
                    <div>
                      Source: <b>{item.source}</b>
                    </div>
                    {/* <div>
                      {formatPhoneNumber(item.phone)}
                    </div>
                    <div>
                      {item.email}
                    </div> */}
                  </div>
                </div>

                <div className="__bottom">

                  <div className="__left">
                    <div>
                      <span className="gray">Name:</span>
                      <span> {item.first_name} {item.last_name}</span>
                    </div>
                    <div>
                      <span className="gray">Address:</span>
                      <span> {item.address}</span>
                    </div>
                  </div>

                  <div className="__right small">
                    <div>
                      {item.message}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          cookie_reportsMaxRows={cookiesSetting?.website_request?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, website_request: { ...cookiesSetting.website_request, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => {
            setLocalInterface({ ...localInterface, page: 1 })
            $setUpdater(Math.random())
          }}
          onPrevPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
            $setUpdater(Math.random())
          }}
          onNextPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
            $setUpdater(Math.random())
          }}
          onPagesEnd={() => {
            setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
            $setUpdater(Math.random())
          }}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />
      </div>
    ) : null}
  </>)
}

export default WebsiteRequestPage_List
